import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'gatsby-link';

import { PageLinkShape } from '../PageNavBar';

import './MainCardsNav.scss';

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavCard = styled(Link)`
  flex-grow: 1;
  text-align: center;
`;

const CSS_NS = 'mainCardsNav';

const MainCardsNav = ({ pages }) => (
  <Container className={CSS_NS}>
    { pages.map(({ path, navTitle }) => (
      <NavCard className={`${CSS_NS}__navCard`} key={path} to={path}>
        { navTitle }
      </NavCard>
    ))}
  </Container>
);

MainCardsNav.propTypes = {
  pages: PropTypes.arrayOf(PageLinkShape).isRequired,
};

export default MainCardsNav;
