import Hero from '../components/Hero';
import MainCardsNav from '../components/MainCardsNav';
import MarkdownContent from '../components/MarkdownContent';
import Page from '../components/Page';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby'
import readNodes from '../utils/readNodes'
import styled from 'styled-components';

import '../components/Blog/BlogPost/BlogPost.scss';

const StartPage = styled(Page)`
  padding-top: 0;
`;

const Hello = styled(MarkdownContent)`
  margin-top: 1rem;
`;

const BlogIntro = styled(MarkdownContent)`
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const IndexPage = ({
  data: {
    pageNodes,
    blogIntro: {
      htmlAst: blogIntroAst,
    },
    hello: {
      htmlAst: helloAst,
    },
    footer: {
      htmlAst: footerAst,
    },
  },
}) => (
  <StartPage footerAst={footerAst} pageNodes={pageNodes}>
    <Hero />
    <Hello contentAst={helloAst} />
    <MainCardsNav pages={readNodes(pageNodes)} />
    <BlogIntro contentAst={blogIntroAst} />
  </StartPage>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
    }).isRequired,
    hello: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
    }).isRequired,
    blogIntro: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
    }).isRequired,
    pageNodes: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query page {
    footer: markdownRemark(frontmatter: { type: { eq: "footer" }}) {
      htmlAst
    }

    hello: markdownRemark(frontmatter: { type: { eq: "hello" }}) {
      htmlAst
    }

    blogIntro: markdownRemark(frontmatter: { type: { eq: "blog-intro" }}) {
      htmlAst
    }

    pageNodes: allNavigationYaml {
      edges {
        node {
          path
          navTitle
        }
      }
    }
  }
`;

export default IndexPage;
